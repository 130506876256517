import React, { useState } from 'react';
import Modal from 'react-modal';
import './Developer.css';
import { Link as Anchor } from 'react-router-dom';

export default function Developer() {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const handleCloseModal = () => {
        setModalIsOpen(false);
    };

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };

    const handleWhatsappMessage = () => {
        const phoneNumber = 5493855865353;
        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <div className='Developer'>
            <p onClick={handleOpenModal}>
                Crea tu E-commerce con nosotros - Creat
            </p>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                className="modal-dev"
                overlayClassName="overlay-dev"
                ariaHideApp={false}
            >
                <div className='modal-dev-contain'>
                    <img src="https://perfectcar.com.ar/wp-content/uploads/2024/08/Creat.png" alt="Creat" className='banner-dev-modal' />
                    <div className='text-dev-modal'>
                        <img src="https://perfectcar.com.ar/wp-content/uploads/2024/08/logocreat.png" alt="Creat" />
                        <h2>Contactanos <br />  <Anchor to='https://Creat.com.ar/' target="_blank">Creat - Marketing Digital</Anchor></h2>
                        <div className='socialsDev'>
                            <Anchor to='https://www.instagram.com/creat.ok' target="_blank"><i className='fa fa-instagram' id="instagram"></i></Anchor>
                            <Anchor to='https://Creat.com.ar/' target="_blank"><i className='fa-regular fa-globe' id="globe"></i></Anchor>
                            <Anchor onClick={handleWhatsappMessage}><i className='fa fa-whatsapp' id="whatsapp"></i></Anchor>
                        </div>
                        <button onClick={handleCloseModal}>OK</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
